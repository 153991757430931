import React from "react";

const NoPage = ({props}) => {
    return (
        <>
            <h1>Stránka nenalezena</h1>
        </>
    );
};

export default NoPage;