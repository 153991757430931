export const CONFIG = {
    uploadDir: process.env.REACT_APP_API_URL + "/upload/",
    endpointEntityList: "/entity/list/",
    endpointEntityEdit: "/entity/edit/",
    endpointEntityItemsEdit: "/entity/items-edit/",
    joedit: {
        readonly: false, // all options from https://xdsoft.net/jodit/docs/,
        disablePlugins: ["spellcheck", "copyformat", "indent", "outdent","font", "color", "speechRecognize","line-height","fontsize", "about", "ai-assistant", "preview", "print", "symbol"],
        language: 'auto',
        spellcheck: false,
        removeButtons: ["about", "symbol"],
        toolbarAdaptive: false,
        addNewLine: false,
        hidePoweredByJodit: true,
        minHeight: 300,
        buttons: [
            'paragraph', '|',
            'bold',
            'italic',
            'underline',
            'strikethrough', '|',
            'link',
            'image',
            'video', '|',
            'ul',
            'ol', '|',
            'table',
            'align', '|',
            'hr',
            'eraser',
            'source',
            'fullsize', '|','undo', 'redo',
        ],
        placeholder: 'Zde můžete psát..'
    }
}